<template>
  <img v-if="isNmh" src="@/assets/images/logo.svg" alt="nmh" />
  <img v-else src="@/assets/images/logo-agentik.png" alt="agentik" />
</template>

<script>
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'Logo',
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    })
  }
}
</script>
