<template>
  <div class="auth-layout">
    <div class="auth-layout__wrapper">
      <div v-if="!hideLogo" class="auth-layout__logo">
        <v-logo class="auth-layout__logo-image" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import VLogo from '@/components/common/VLogo.vue'

export default {
  name: 'AuthLayout',
  components: { VLogo },
  props: {
    hideLogo: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';

.auth-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-layout__wrapper {
  max-width: 336px;
  width: 100%;
  transition: min-width $--transition-duration;

  @include phone {
    max-width: 272px;
  }
}

.auth-layout__logo {
  text-align: center;
  margin-bottom: 32px;

  &-image {
    width: 77px;
  }
}
</style>
